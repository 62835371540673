a {
    text-decoration: none;
}

*::placeholder, button {
    /* font-family: "Lato", sans-serif; */
    font-family: 'Open Sans', sans-serif;
}

body {
    margin: 0;
    /* font-family: "Lato", sans-serif; */
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
